import React, { useState } from 'react';
import { Subtitle, BigInput, Body, Title, InfoImage, Wrapper, Select, TextArea, TextInput, WrapperInput, Container, WrapperSelectImage, SelectImage, Image, TextFile, Button, Error } from './styled';
import * as yup from 'yup';
import { Formik } from 'formik';
import imageIcon from '../../images/image-icon.svg'
import deleteImageIcon from '../../images/delete-image-icon.svg'
import axios from 'axios'
const schemaFormik = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Escreva o seu melhor email"),
  fullName: yup
    .string()
    .required("Escreva o seu nome"),
  phone: yup
    .string()
    .required("Digite o seu telefone com DDD").min(10, "Está faltando algum número"),
  description: yup
    .string()
    .required("Descreva o seu problema ou dúvida").min(60, "Por favor, descreva um pouco mais"),
  model: yup
    .string()
    .required("Escreva o modelo do celular, ex: Galaxy J7"),
});
export default function SuportBody() {
  const [data, setData] = useState([]);
  const [serverState, setServerState] = useState()
  // let data = [];

  function handleDelete(index) {
    setData(data.filter(element => data[index] !== element))
  }

  function handleChange(fun, values) {
    let array = []
    for (let i = 0; i < values.length; i++) {
      array.push(values[i])
    }
    setData(array)
    fun("files", values)
    console.log(data)
  }
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };
  async function _handleSubmit(values, actions) {
    console.log(values)
    console.log(actions)
    axios({
      method: "POST",
      url: "https://formspree.io/f/xbjpyzqg",
      data: values
    })
      .then(response => {

        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, "Thanks!");
      })
      .catch(error => {
        console.log(error)
        actions.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
      });
  }
  function renderInfoImages() {

    return data.map((element, i) => {
      return <InfoImage>
        <TextFile>{element.name}</TextFile>
        <Image src={deleteImageIcon} onClick={() => handleDelete(i)} />
      </InfoImage>
    })

  }
  return (
    <Body >
      <Title>Fale conosco</Title>
      <Wrapper>
        <Subtitle> <b>Problemas ou dúvidas?</b> Nos mande uma mensagem e responderemos o mais rápido possível.</Subtitle>
      </Wrapper>
      <div>
        <Formik
          initialValues={{
            email: '',
            fullName: '',
            phone: '',
            description: '',
            role: 'Paciente',
            platform: 'Web',
            browser: 'Chrome',
            model: 'vazio',

          }}
          onSubmit={_handleSubmit}

          validationSchema={schemaFormik}
          render={(props) => (
            <Body>
              <form onSubmit={props.handleSubmit} enctype="multipart/form-data">
                <Container>
                  <Wrapper>
                    <div>
                      <WrapperInput>
                        <text>Seu nome completo*</text>
                        <TextInput
                          type="text"
                          name="fullName"
                          placeholder="Seu nome completo"
                          value={props.values.fullName}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </WrapperInput>
                      <Error >{props.errors.fullName && props.errors.fullName}</Error>
                      <WrapperInput>
                        <text>Seu E-mail*</text>
                        <TextInput
                          type="email"
                          name="email"
                          placeholder="Seu email"
                          onChange={props.handleChange}
                          onBlur={props.onBlur}
                          value={props.values.email}
                        />
                      </WrapperInput>
                      <Error >{props.errors.email && props.errors.email}</Error>
                      <WrapperInput>
                        <text>DDD + Telefone*</text>
                        <TextInput
                          type="text"
                          name="phone"
                          placeholder="Seu número"
                          onChange={props.handleChange}
                          onBlur={props.onBlur}
                          value={props.values.phone}
                        />
                      </WrapperInput>
                      <Error >{props.errors.phone && props.errors.phone}</Error>
                      <div>
                      </div>
                      <WrapperInput>
                        <text>Qual é seu perfil de acesso na plataforma?*</text>
                        <Select value={props.values.role} onChange={(e) => props.setFieldValue('role', e.target.value)}>
                          <option value="Paciente">Paciente</option>
                          <option value="Gestor">Gestor</option>
                          <option value="Profissional">Profissional de saúde</option>
                        </Select>
                      </WrapperInput>
                      <Error >{props.errors.role && props.errors.role}</Error>
                      <WrapperInput>
                        <text>Plataforma que usou ?*</text>
                        <Select value={props.values.platform} onChange={(e) => props.setFieldValue('platform', e.target.value)}>
                          <option value="Web">Web</option>
                          <option value="Mobile">Aplicativo</option>
                        </Select>
                      </WrapperInput>
                      <Error >{props.errors.platform && props.errors.platform}</Error>
                      {props.values.platform === "Web" ?
                        <>

                          <WrapperInput>
                            <text>Navegador*</text>
                            <Select value={props.values.browser} onChange={(e) => props.setFieldValue('browser', e.target.value)}>
                              <option value="Web">Chrome</option>
                              <option value="Mobile">Mozilla firefox</option>
                              <option value="Mobile">Safari</option>
                            </Select>
                          </WrapperInput>
                          <Error>{props.errors.browser && props.errors.browser}</Error>
                        </> :
                        <>
                          <WrapperInput>
                            <text>Modelo do celular*</text>
                            <TextInput
                              placeholder="Escreva o modelo do seu celular"
                              name="model"
                              type="text"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.model}
                            />
                          </WrapperInput>
                          <Error >{props.errors.model && props.errors.model}</Error>
                        </>}

                    </div>
                    <div>
                      <BigInput>
                        <text>Dúvida ou Problema*</text>
                        <TextArea
                          placeholder="Escreva aqui sua dúvida ou problema"
                          name="description"
                          type="text"
                          onChange={props.handleChange}
                          onBlur={props.onBlur}
                          value={props.values.description}
                          type="textarea"
                        />
                      </BigInput>
                      <Error >{props.errors.description && props.errors.description}</Error>
                      <WrapperSelectImage >
                        <SelectImage >
                          <Image src={imageIcon} />
                          <input accept="image/png, image/jpeg" multiple type="file" onChange={(event) => handleChange(props.setFieldValue, event.currentTarget.files)
                          } style={{ display: "none" }}
                          />

                        </SelectImage>

                        {data.length > 0 ?
                          <Container>
                            {renderInfoImages()}
                          </Container>
                          : <text>Você pode anexar imagens
                     nos formatos PNG ou JPEG.</text>}

                      </WrapperSelectImage>
                      <Error >{props.errors.files && props.errors.files}</Error>
                    </div>
                  </Wrapper>
                  <Button type="submit" disabled={props.isSubmitting}>
                    <h3>Enviar</h3>
                  </Button>
                </Container>
              </form>
            </Body>
          )}
        />
      </div>
    </Body>

  );
}
