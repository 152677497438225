import React from 'react'
import Header from '../components/header'
import SEO from '../components/seo'
import SuportBody from '../components/suporte'
import Global from '../styles/global'

export default function Suporte() {
  return (
    <>
      <SEO title="Suporte" />
      <Global />
      <Header />
      <SuportBody />
    </>
  )
}
