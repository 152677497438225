import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';

export const Container = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  /* min-width:fit-content; */
  
`;

export const Body = styled.div`
  display:flex;
  flex-direction:column;
  margin:10px;
  align-items:center; 
`;
export const Select = styled.select`
  border:none;
  padding:2px;
  background-color:${colorPalette.white};
`;

export const Option = styled.option`
padding:10px;
  margin:5px;
`;
export const TextInput = styled.input`
  flex:1;
  outline:none;
  border:none;
`;

export const WrapperInput = styled.div`
  display:flex;
  flex-direction: column;
  margin:5px;
  min-width:280px;
  border: 1px solid ${colorPalette.primary};
  border-radius:4px;
  padding:5px;
  text{
    font-size:10px;
    color: ${colorPalette.disabledColor};
  }
`;

export const Title = styled.h2`
  color:${colorPalette.primary};
  font-weight:bold;
`;

export const Subtitle = styled.h4`
  color: #000;
  max-width:419px;
  font-weight: ${({ bold }) => bold ? '700' : 'normal'};
`;

export const Error = styled.text`
  color: ${colorPalette.error};
  font-size:14px;
  margin-left:5px;
`;

export const Wrapper = styled.div`
  display:flex;
  /* max-width:600px; */
  flex-wrap:wrap;
  flex-direction:row;
  justify-content:center;
  align-items:flex-start;
`;

export const BigInput = styled.div`

  display:flex;
  flex-direction: column;
  margin:5px;
  border: 1px solid ${colorPalette.primary};
  border-radius:4px;
  padding:5px;
  text{
    font-size:10px;
    color: ${colorPalette.disabledColor};
  }
`;

export const TextArea = styled.textarea`
  outline:none;
  border:none;
  width:270px;
  height:180px;
  resize:none;
`;

export const WrapperSelectImage = styled.div`
  display:flex;
  align-items:flex-start;
  text{
    flex-wrap:wrap;
    font-size:10px;
    color: ${colorPalette.disabledColor};
    padding-top:15px;
    max-width:200px;
  }
`;
export const SelectImage = styled.label`
  padding:10px;
  border:2px solid ${colorPalette.primary};
  border-radius:5px;
  margin:5px;

`;
export const Image = styled.img`
  min-width:25px;

`;
export const InfoImage = styled.div`
  display:flex;
  margin:5px;
  flex-direction:row;
`;

export const TextFile = styled.h4`
  font-size:14px;
  font-weight:bold;
  white-space: nowrap;
  overflow: hidden;
  max-width:180px;
  text-overflow: ellipsis;
  padding-right:5px;
  color: ${colorPalette.primary};
`;

export const Info = styled.text`
  display:flex;
  color: ${colorPalette.disabledColor};
  font-size:11px;
  line-height:12px;
  padding-left:10px;
  max-width:290px;
`;

export const Button = styled.button`

  width:280px;
  height: 44px;
  background-color:${colorPalette.primary};
  color:${colorPalette.white};
  outline:none;
  text-decoration:none;
`;